@charset "utf-8";

// layout
// ----------------------------------------------------------
// break point
$breakPoint: 768px;

// mainW
$mainW: 1280px;
$mainMaxW: 1440px;

// Basis vw
$basis: 1440;
$smBasis: 375;

// contentsW
$contentsW: 1022px;

// Basis space
$space: 30px;
$smSpace: 24px;

// Basis width
$smBasisW: 375px;

// headerH
$headerH: 100px;
$smHeaderH: 70px;

// smBtnW
$smBtnW: calc(100% - 8px);

// font
// ----------------------------------------------------------
$font--Nunito: 'Nunito', sans-serif;
$fontFamily: "Noto Sans CJK JP", "Noto Sans JP", sans-serif;

$fontSize: 16px;
$lineHeight: (24/14);

// color
// ----------------------------------------------------------
$color--body: #fff;

$color--text: #121212;
$color--link: #012f61;

// border
$color--border: #e4e4eb;

// color
$color--white: rgba(#fff, 1);
$color--gray: #ccc;
$color--blue: #35318f;
$color--blue--thin: #f1f9ff;
$color--purple: #322f86;
$color--purple--thin: #7b79bc;
$color--orange: #ffab86;
$color--orange--dark: #f47943;
$color--red: #ee3d43;
$color--pink: #f27ca1; // TODO $color--cate--tvと関連がある場合は統合すること

// cate color
$color--cate--tv: #ed7836;
$color--cate--net: #5296d1;
$color--cate--tel: #02a77d;
$color--cate--air: #008d60;
$color--cate--hikari: #feba4e;
$color--cate--internet: #3c87c9;
$color--cate--cableplustel: #00a99d;

// shadow
// ----------------------------------------------------------
$shadow: 0 5px 10px rgba(85, 85, 93, .11);

// easing
// ----------------------------------------------------------
$easeInSine: cubic-bezier(.47, 0, .745, .715);
$easeOutSine: cubic-bezier(.39, .575, .565, 1);
$easeInOutSine: cubic-bezier(.445, .05, .55, .95);
$easeInQuad: cubic-bezier(.55, .085, .68, .53);
$easeOutQuad: cubic-bezier(.25, .46, .45, .94);
$easeInOutQuad: cubic-bezier(.455, .03, .515, .955);
$easeInCubic: cubic-bezier(.55, .055, .675, .19);
$easeOutCubic: cubic-bezier(.215, .61, .355, 1);
$easeInOutCubic: cubic-bezier(.645, .045, .355, 1);
$easeInQuart: cubic-bezier(.895, .03, .685, .22);
$easeOutQuart: cubic-bezier(.165, .84, .44, 1);
$easeInOutQuart: cubic-bezier(.77, 0, .175, 1);
$easeInQuint: cubic-bezier(.755, .05, .855, .06);
$easeOutQuint: cubic-bezier(.23, 1, .32, 1);
$easeInOutQuint: cubic-bezier(.86, 0, .07, 1);
$easeInExpo: cubic-bezier(.95, .05, .795, .035);
$easeOutExpo: cubic-bezier(.19, 1, .22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInCirc: cubic-bezier(.6, .04, .98, .335);
$easeOutCirc: cubic-bezier(.075, .82, .165, 1);
$easeInOutCirc: cubic-bezier(.785, .135, .15, .86);
$easeInBack: cubic-bezier(.6, -.28, .735, .045);
$easeOutBack: cubic-bezier(.175, .885, .32, 1.275);
$easeInOutBack: cubic-bezier(.68, -.55, .265, 1.55);

// 共通easing
// ----------------------------------------------------------
$comMS: 470ms;
$comMSFirst: 250ms;
$comES: ease;

// keyframes
// ----------------------------------------------------------

@keyframes jump {

  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(3px);
  }

  65% {
    transform: translateY(-3px);
  }

  100% {
    transform: translateY(0);
  }
}

$green : #22ac38;