@keyframes jump {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(3px);
  }
  65% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}

.p-list {
  padding: 40px 0 80px;
}

.p-list__title {
  font-size: 25px;
  line-height: 1.5;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .p-list__title {
    font-size: 18px;
  }
}

.p-list__title .num {
  font-size: 15px;
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .p-list__title .num {
    font-size: 13px;
  }
}

.p-list__title .num .red {
  color: #ff0000;
}

@media screen and (max-width: 767px) {
  .p-list__items {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.p-list-item {
  display: flex;
  justify-content: flex-start;
  border: 1px solid #e5e5e5;
}

.p-list-item + .p-list-item {
  margin-top: 40px;
}

.p-list-check {
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #eeeeee;
}

@media screen and (max-width: 767px) {
  .p-list-check {
    width: 35px;
  }
}

.p-list-check input {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}

.p-list-check input:checked + .p-list-check__parts {
  background: url(../../img/common/icon_check02.png) center no-repeat white;
  background-size: 21px auto;
}

.p-list-check__parts {
  width: 27px;
  height: 27px;
  background-color: #fff;
  border: 2px solid #bfbfbf;
  position: relative;
}

@media screen and (max-width: 767px) {
  .p-list-check__parts {
    width: 24px;
    height: 24px;
  }
}

.p-list-detail {
  padding: 45px 70px 20px 20px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .p-list-detail {
    padding: 20px;
  }
}

.p-list-detail__main {
  display: flex;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .p-list-detail__main {
    display: block;
    margin-bottom: 10px;
  }
}

.p-list-detail__main .image {
  width: 200px;
  height: 150px;
  margin-right: 35px;
  flex-shrink: 0;
}

.p-list-detail__main .image img {
  object-fit: cover;
  font-family: "object-fit: cover;";
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .p-list-detail__main .image {
    width: 100%;
    height: 180px;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.p-list-detail__main .textarea {
  position: relative;
  width: 100%;
}

.p-list-detail__main .textarea__fav {
  position: absolute;
  top: 0;
  right: 0;
  color: #007bf5;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .p-list-detail__main .textarea__fav {
    position: static;
    font-size: 13px;
    margin-bottom: 15px;
    display: inline-block;
  }
}

.p-list-detail__main .textarea__title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .p-list-detail__main .textarea__title {
    margin-bottom: 5px;
  }
}

.p-list-detail__main .textarea__access {
  color: #808080;
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .p-list-detail__main .textarea__access {
    margin-bottom: 10px;
  }
}

.p-list-detail__main .textarea__detail {
  padding: 20px 15px;
  background-color: #fafafa;
}

@media screen and (max-width: 767px) {
  .p-list-detail__main .textarea__detail {
    padding: 10px;
  }
}

.p-list-detail__main .textarea__detail p {
  font-size: 14px;
  line-height: 1.78571;
}

.p-list-detail .c-menu li:nth-child(n + 3) {
  display: none;
}

.p-list-detail__btn {
  margin-top: 20px;
  text-align: center;
}

.p-list-detail__btn a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #e5e5e5;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.78571;
  padding: 5px 41px;
}

@media screen and (max-width: 767px) {
  .p-list-detail__btn a {
    padding: 5px;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  body {
    padding-bottom: 80px;
  }
}

.p-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.24);
  padding: 10px;
  display: none;
}

@media screen and (max-width: 767px) {
  .p-popup {
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.p-popup__women {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.p-popup__text01 {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-bottom: 10px;
}

.p-popup__text01 img {
  margin-right: 5px;
}

.p-popup__btns {
  display: flex;
}

@media screen and (max-width: 767px) {
  .p-popup__btns {
    justify-content: space-between;
  }
}

.p-popup__btns a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 120px;
  height: 50px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.38462;
}

@media screen and (max-width: 767px) {
  .p-popup__btns a {
    height: 40px;
    width: 32%;
  }
}

.p-popup__btns a + a {
  margin-left: 5px;
}

@media screen and (max-width: 767px) {
  .p-popup__btns a + a {
    margin-left: 0;
  }
}

.p-popup__btns a.orange {
  background-color: #ff6e20;
  color: white;
}

.p-popup__btns a.green {
  border: 2px solid #32c1b2;
  color: #32c1b2;
  background-color: #fff;
}

.p-popup__text02 {
  font-size: 13px;
  line-height: 1.38462;
  margin-top: 5px;
}
