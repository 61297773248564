@charset "utf-8";
@import "../f-variables"; //
@import "../f-mixin"; // 定義モジュール

.p-list {
	padding: 40px 0 80px;
	@include media(sm) {
	}
	&__title {
		font-size: 25px;
		line-height: 1.5;
		margin-bottom: 30px;
		@include media(sm) {
			font-size: 18px;
		}
		.num {
			font-size: 15px;
			margin-left: 10px;
			@include media(sm) {
				font-size: 13px;
			}
			.red {
				color: #ff0000;
			}
		}
	}
	&__items {
		@include media(sm) {
			margin-left: -20px;
			margin-right: -20px;
		}
	}
	&-item {
		$item: &;
		display: flex;
		justify-content: flex-start;
		border: 1px solid #e5e5e5;
		+ #{$item} {
			margin-top: 40px;
		}
	}
	&-check {
		width: 55px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		$check: &;
		background-color: #eeeeee;
		@include media(sm) {
			width: 35px;
		}
		input {
			position: absolute;
			visibility: hidden;
			opacity: 0;
			&:checked {
				+ #{$check}__parts {
					background: url(../../img/common/icon_check02.png) center no-repeat white;
					background-size: 21px auto;
				}
			}
		}
		&__parts {
			width: 27px;
			height: 27px;
			background-color: #fff;
			border: 2px solid #bfbfbf;
			position: relative;
			@include media(sm) {
				width: 24px;
				height: 24px;
			}
		}
	}
	&-detail {
		padding: 45px 70px 20px 20px;
		width: 100%;
		@include media(sm) {
			padding: 20px;
		}
		&__main {
			display: flex;
			margin-bottom: 20px;
			@include media(sm) {
				display: block;
				margin-bottom: 10px;
			}
			.image {
				width: 200px;
				height: 150px;
				@include ofi();
				margin-right: 35px;
				flex-shrink: 0;
				@include media(sm) {
					width: 100%;
					height: 180px;
					margin-right: 0;
					margin-bottom: 20px;
				}
			}
			.textarea {
				position: relative;
				width: 100%;
				&__fav {
					position: absolute;
					top: 0;
					right: 0;
					color: #007bf5;
					font-size: 12px;
					@include media(sm) {
						position: static;
						font-size: 13px;
						margin-bottom: 15px;
						display: inline-block;
					}
				}
				&__title {
					font-size: 20px;
					font-weight: bold;
					line-height: 1.5;
					margin-bottom: 15px;
					@include media(sm) {
						margin-bottom: 5px;
					}
				}
				&__access {
					color: #808080;
					font-size: 12px;
					line-height: 1.5;
					margin-bottom: 20px;
					@include media(sm) {
						margin-bottom: 10px;
					}
				}
				&__detail {
					padding: 20px 15px;
					background-color: #fafafa;
					@include media(sm) {
						padding: 10px;
					}
					p {
						@include fz(14, 25);
					}
				}
			}
		}
		.c-menu {
			li {
				&:nth-child(n + 3) {
					display: none;
				}
			}
		}

		&__btn {
			margin-top: 20px;
			text-align: center;
			a {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background-color: #e5e5e5;
				border-radius: 5px;
				@include fz(14, 25, 500);
				padding: 5px 41px;
				@include media(sm) {
					padding: 5px;
					width: 100%;
				}
			}
		}
	}
}
body {
	@include media(sm) {
		padding-bottom: 80px;
	}
}
.p-popup {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 99;
	background-color: #fff;
	box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.24);
	padding: 10px;
	display: none;
	@include media(sm) {
		bottom: 0;
		left: 0;
		right: 0;
	}
	&__women {
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: -1;
	}
	&__text01 {
		display: flex;
		align-items: center;
		font-size: 13px;
		margin-bottom: 10px;
		img {
			margin-right: 5px;
		}
	}
	&__btns {
		display: flex;
		@include media(sm) {
			justify-content: space-between;
		}
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			width: 120px;
			height: 50px;
			@include fz(13, 18, 400);
			@include media(sm) {
				height: 40px;
				width: 32%;
			}
			+ a {
				margin-left: 5px;
				@include media(sm) {
					margin-left: 0;
				}
			}
			&.orange {
				background-color: #ff6e20;
				color: white;
			}
			&.green {
				border: 2px solid #32c1b2;
				color: #32c1b2;
				background-color: #fff;
			}
		}
	}
	&__text02 {
		@include fz(13, 18);
		margin-top: 5px;
	}
}
