@charset "utf-8";

// mixin集
// ----------------------------------------------------------

// 03__フォントサイズとラインハイトまとめて指定
// ----------------------------------------------------------

@mixin fz($size, $height: false, $weight: false) {
	font-size: #{$size}px;

	@if $weight {
		font-weight: $weight;
	}

	@if $height {
		line-height: $height / $size;
	}
}

@mixin fzs($size, $height: false, $weight: false) {
	$size: $size/2;
	$height: $height/2;

	font-size: #{$size}px;

	@if $weight {
		font-weight: $weight;
	}

	@if $height {
		line-height: $height / $size;
	}
}

// 04__letter-spacingをトラッキングの数値から計算
// ----------------------------------------------------------

@mixin letterSpacing($tracking) {
	letter-spacing: #{$tracking / 1000}em;
}

// 06__media query
// ----------------------------------------------------------

@mixin media($size, $px: false) {
	@if ($size == "sm") {
		@media screen and (max-width: $breakPoint - 1) {
			@content;
		}
	} @else if ($size == "se") {
		@media screen and (max-width: 360px) {
			@content;
		}
	} @else if ($size == "mid") {
		@media screen and (min-width: $breakPoint) and (max-width: $mainW - 1), print {
			@content;
		}
	} @else if ($size == "lg") {
		@media screen and (min-width: $breakPoint), print {
			@content;
		}
	} @else if ($size == "retina") {
		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
			@content;
		}
	}

	@if ($px) {
		@if ($size == "max-width") {
			@media screen and (max-width: $px) {
				@content;
			}
		} @else if ($size == "min-width") {
			@media screen and (min-width: $px) {
				@content;
			}
		} @else if ($size == "max-height") {
			@media screen and (max-height: $px) {
				@content;
			}
		} @else if ($size == "min-height") {
			@media screen and (min-height: $px) {
				@content;
			}
		}
	}
}
// 13__ie11 (lg / sm)
// ----------------------------------------------------------

@mixin ie11($size: false) {
	html[data-browser="ie"][data-browserversion="11"] & {
		@if ($size) {
			@if ($size == "lg") {
				@media screen and (min-width: $breakPoint) {
					@content;
				}
			} @else if ($size == "sm") {
				@media screen and (max-width: $breakPoint - 1) {
					@content;
				}
			}
		} @else {
			@content;
		}
	}
}

@mixin ofi() {
	img {
		object-fit: cover;
		font-family: "object-fit: cover;";
		width: 100%;
		height: 100%;
	}
}
